import {Route, Routes} from "react-router-dom";
import {RoutingDefinition} from "digimuth-components/dist/components/crudBreadcrumb/crudBreadcrumb";
import styles from "./routingContainer.module.less";
import {LoadingOutlined} from "@ant-design/icons";
import {Spin} from "antd";
import * as Pages from "./pages";
import {VinificationStockOperationForm} from "./pages";

const mainPage = {route: "/", name: "Strona główna"};

export const RoutingPaths: { [key: string]: RoutingDefinition } = {
    vinificationAdditiveAdd: { route: "/vinificationAdditive/add" },
    vinificationAdditiveEdit: { route: "/vinificationAdditive/:id/edit" },
    vinificationAdditiveTable: { route: "/vinificationAdditive" },

    physicochemicalTestAdd: {route: "/productionProcess/:productionProcessId/physicochemicalTest/add"},
    physicochemicalTestEdit: {route: "/productionProcess/:productionProcessId/physicochemicalTest/:id/edit"},
    physicochemicalTestTable: {route: "/productionProcess/:productionProcessId/physicochemicalTest"},

    strainAdd: { route: "/strain/add" },
    strainEdit: { route: "/strain/:id/edit" },
    strainTable: { route: "/strain" },

    cropAdd: { route: "/crop/add" },
    cropEdit: { route: "/crop/:id/edit" },
    cropTable: { route: "/crop" },

    productionProcessAdd: { route: "/productionProcess/add" },
    productionProcessEdit: { route: "/productionProcess/:id/edit" },
    productionProcessTable: { route: "/productionProcess" },

    inventoryOperationAdd: {route: "/inventoryOperation/add"},
    inventoryOperationEdit: {route: "/inventoryOperation/:id/edit"},
    inventoryOperationTable: {route: "/inventoryOperation"},

    contractorAdd: {route: "/contractor/add"},
    contractorEdit: {route: "/contractor/:id/edit"},
    contractorTable: {route: "/contractor"},
    contractorHistory: {route: "/contractor/:id/history"},
    contractorOperationHistory: {route: "/contractor/:id/operationHistory"},

    warehouseOperationsCalendar: {route: "/calendar"},

    unitOfMeasureAdd: {route: "/unitOfMeasure/add"},
    unitOfMeasureEdit: {route: "/unitOfMeasure/:id/edit"},
    unitOfMeasureTable: {route: "/unitOfMeasure"},

    internalStockOperationDetailsTable: {route: "/internalStockOperation/:stockOperationId"},
    productionStockOperationDetailsTable: {route: "/productionStockOperation/:stockOperationId"},
    processStockOperationDetails: {route: "/processStockOperationDetails/:processId"},
    stockOperationAdd: {route: "/stockOperation/add/:warehouseId/:operationType"},
    stockOperationProcessAdd: {route: "/stockOperation/add/:processId/"},
    stockOperationAddNoParams: {route: "/stockOperation/add"},
    productionStockOperationAddNoParams: {route: "/productionStockOperation/add"},
    vinificationStockOperationForm: {route: "/vinificationStockOperationForm/:stage/:processId"},

    stockOperationEdit: {route: "/stockOperation/:id/edit"},
    productionStockOperationEdit: {route: "/productionStockOperation/:id/edit"},
    companyDataEdit: {route: "/companyData/edit"},
    companyData: {route: "/companyData"},
    companyDataHistory: {route: "/companyData/details"},

    stockOperationTable: {route: "/stockOperation"},
    stockOperationDetailsTable: {route: "/stockOperation/:stockOperationId"},
    stockOperationHistory: {route: "/stockOperation/:id/history"},
    stockGeneralTable: {route: "/generalStock"},
    stockReservationHistory: {route: "/stock/:productId/:warehouseId/reservationHistory"},

    productStockTable: {route: "/stock/product/:productId"},
    productStockHistory: {route: "/stock/product/:productId/warehouse/:warehouseId/history"},
    warehouseStockTable: {route: "/stock/warehouse/:warehouseId"},
    productAdd: {route: "/product/add"},
    productEdit: {route: "/product/:id/edit"},
    productTable: {route: "/product"},
    productUpdateHistory: {route: "/product/:id/history"},

    warehouseAdd: {route: "/warehouse/add"},
    subWarehouseAdd: {route: "/warehouse/add/:parentWarehouseId"},
    warehouseDetails: {route: "/warehouse/:id/details"},
    warehouseEdit: {route: "/warehouse/:id/edit"},
    warehouseTable: {route: "/warehouse"},
    subWarehouseTable: {route: "/warehouse/:parentWarehouseId"},

    userTable: {route: "/user"},
    userAdd: {route: "/user/add"},
    userEdit: {route: "/user/:id/edit"},
    userDetails: {route: "/user/:id/details"},

    mainPage
};

const loadingIcon = <LoadingOutlined className={styles.loadingIcon} spin/>;
export const loadingSpin = <Spin className={styles.loadingContainer} indicator={loadingIcon}/>;

export default function RoutingContainer() {
    return <Routes>
        <Route path={RoutingPaths.physicochemicalTestAdd.route} element={<Pages.PhysicochemicalTestFormPage/>}/>
        <Route path={RoutingPaths.physicochemicalTestEdit.route} element={<Pages.PhysicochemicalTestFormPage/>}/>
        <Route path={RoutingPaths.physicochemicalTestTable.route} element={<Pages.PhysicochemicalTestPage/>}/>

        <Route path={RoutingPaths.strainAdd.route} element={<Pages.StrainFormPage/>}/>
        <Route path={RoutingPaths.strainEdit.route} element={<Pages.StrainFormPage/>}/>
        <Route path={RoutingPaths.strainTable.route} element={<Pages.StrainPage/>}/>

        <Route path={RoutingPaths.cropAdd.route} element={<Pages.CropFormPage/>}/>
        <Route path={RoutingPaths.cropEdit.route} element={<Pages.CropFormPage/>}/>
        <Route path={RoutingPaths.cropTable.route} element={<Pages.CropPage/>}/>

        <Route path={RoutingPaths.productionProcessAdd.route} element={<Pages.ProductionProcessFormPage/>}/>
        <Route path={RoutingPaths.productionProcessEdit.route} element={<Pages.ProductionProcessFormPage/>}/>
        <Route path={RoutingPaths.productionProcessTable.route} element={<Pages.ProductionProcessPage/>}/>

        <Route path={RoutingPaths.inventoryOperationAdd.route} element={<Pages.InventoryOperationFormPage/>}/>
        <Route path={RoutingPaths.inventoryOperationEdit.route} element={<Pages.InventoryOperationFormPage/>}/>
        <Route path={RoutingPaths.inventoryOperationTable.route} element={<Pages.InventoryOperationPage/>}/>

        <Route path={RoutingPaths.contractorAdd.route} element={<Pages.ContractorFormPage/>}/>
        <Route path={RoutingPaths.contractorEdit.route} element={<Pages.ContractorFormPage/>}/>
        <Route path={RoutingPaths.contractorTable.route} element={<Pages.ContractorPage/>}/>
        <Route path={RoutingPaths.contractorHistory.route} element={<Pages.ContractorHistoryPage/>}/>
        <Route path={RoutingPaths.contractorOperationHistory.route} element={<Pages.ContractorOperationHistoryPage/>}/>
        <Route path={RoutingPaths.vinificationStockOperationForm.route} element={<Pages.VinificationStockOperationForm/>}/>

        <Route path={RoutingPaths.warehouseOperationsCalendar.route}
               element={<Pages.WarehouseOperationsCalendarPage/>}/>

        <Route path={RoutingPaths.unitOfMeasureAdd.route} element={<Pages.UnitOfMeasureFormPage/>}/>
        <Route path={RoutingPaths.unitOfMeasureEdit.route} element={<Pages.UnitOfMeasureFormPage/>}/>
        <Route path={RoutingPaths.unitOfMeasureTable.route} element={<Pages.UnitOfMeasurePage/>}/>

        <Route path={RoutingPaths.internalStockOperationDetailsTable.route}
               element={<Pages.InternalStockOperationDetailsPage/>}/>

        <Route path={RoutingPaths.productionStockOperationDetailsTable.route}
               element={<Pages.ProductionStockOperationDetailsPage/>}/>

        <Route path={RoutingPaths.processStockOperationDetails.route}
               element={<Pages.ProcessStockOperationDetailsPage/>}/>

        <Route path={RoutingPaths.stockOperationAdd.route} element={<Pages.StockOperationFormPage/>}/>
        <Route path={RoutingPaths.stockOperationProcessAdd.route} element={<Pages.StockOperationFormPage/>}/>
        <Route path={RoutingPaths.stockOperationAddNoParams.route} element={<Pages.StockOperationFormPage/>}/>
        <Route path={RoutingPaths.productionStockOperationAddNoParams.route}
               element={<Pages.ProductionStockOperationFormPage/>}/>
        <Route path={RoutingPaths.stockOperationTable.route} element={<Pages.StockOperationPage/>}/>
        <Route path={RoutingPaths.stockOperationDetailsTable.route} element={<Pages.StockOperationDetailsPage/>}/>

        <Route path={RoutingPaths.companyDataEdit.route} element={<Pages.CompanyDataFormPage/>}/>
        <Route path={RoutingPaths.companyData.route} element={<Pages.CompanyDataPage/>}/>
        <Route path={RoutingPaths.companyDataHistory.route} element={<Pages.CompanyDataHistoryPage/>}/>

        <Route path={RoutingPaths.stockOperationAdd.route} element={<Pages.StockOperationFormPage/>}/>
        <Route path={RoutingPaths.stockOperationTable.route} element={<Pages.StockOperationPage/>}/>
        <Route path={RoutingPaths.stockOperationDetailsTable.route} element={<Pages.StockOperationDetailsPage/>}/>
        <Route path={RoutingPaths.stockOperationHistory.route} element={<Pages.StockOperationUpdateHistoryPage/>}/>
        <Route path={RoutingPaths.stockGeneralTable.route} element={<Pages.GeneralStockPage/>}/>
        <Route path={RoutingPaths.stockReservationHistory.route} element={<Pages.StockReservationHistoryPage/>}/>

        <Route path={RoutingPaths.productStockTable.route} element={<Pages.StockPage/>}/>
        <Route path={RoutingPaths.productStockHistory.route} element={<Pages.StockHistoryPage/>}/>
        <Route path={RoutingPaths.warehouseStockTable.route} element={<Pages.StockPage/>}/>

        <Route path={RoutingPaths.productAdd.route} element={<Pages.ProductFormPage/>}/>
        <Route path={RoutingPaths.productEdit.route} element={<Pages.ProductFormPage/>}/>
        <Route path={RoutingPaths.stockOperationEdit.route} element={<Pages.StockOperationFormPage/>}/>
        <Route path={RoutingPaths.productionStockOperationEdit.route}
               element={<Pages.ProductionStockOperationFormPage/>}/>
        <Route path={RoutingPaths.productTable.route} element={<Pages.ProductPage/>}/>
        <Route path={RoutingPaths.productUpdateHistory.route} element={<Pages.ProductUpdateHistoryPage/>}/>

        <Route path={RoutingPaths.warehouseAdd.route} element={<Pages.WarehouseFormPage/>}/>
        <Route path={RoutingPaths.subWarehouseAdd.route} element={<Pages.WarehouseFormPage/>}/>
        <Route path={RoutingPaths.warehouseEdit.route} element={<Pages.WarehouseFormPage/>}/>
        <Route path={RoutingPaths.warehouseTable.route} element={<Pages.WarehousePage/>}/>
        <Route path={RoutingPaths.warehouseDetails.route} element={<Pages.WarehouseDetailsPage/>}/>
        <Route path={RoutingPaths.subWarehouseTable.route} element={<Pages.WarehousePage/>}/>

        <Route path={RoutingPaths.userTable.route} element={<Pages.UserPage/>}/>
        <Route path={RoutingPaths.userAdd.route} element={<Pages.UserFormPage/>}/>
        <Route path={RoutingPaths.userEdit.route} element={<Pages.UserFormPage/>}/>
        <Route path={RoutingPaths.userDetails.route} element={<Pages.UserDetailsPage/>}/>

        <Route path={"/"} element={<Pages.MainPage/>}/>
    </Routes>
}
